<template>
  <main id="faq">
    <v-sheet>
      <v-container style="max-width: 1280px">
        <v-row justify="center">
          <v-col cols="12">
            <div class="text-h4 font-weight-light my-4">
              Frequently Asked Questions
            </div>
          </v-col>
        </v-row>
        <v-row justify="center">
          <v-col cols="12">
            <v-expansion-panels>
              <v-expansion-panel v-for="(entry, i) in faqEntries" :key="'faq-' + i">
                <v-expansion-panel-header color="grey lighten-3">
                  <div class="text-overline">
                    {{ i + 1 }}. {{ entry.question }}
                  </div>
                </v-expansion-panel-header>
                <v-divider></v-divider>
                <v-expansion-panel-content class="text-left pt-8">
                  <p class="text-body-1 mb-4" v-for="(para, j) in entry.paragraphs" :key="'para-' + i + '-' + j">
                    {{ para }}
                  </p>
                </v-expansion-panel-content>
              </v-expansion-panel>
            </v-expansion-panels>
          </v-col>
        </v-row>
      </v-container>
    </v-sheet>
  </main>
</template>

<script>
export default {
  name: "FAQ",
  data: () => ({
    faqEntries: [
      {
        question: "My One Rule is just the golden rule, so why the big deal?",
        paragraphs: [
          `The simplest rules are the ones most often ignored. Cliches are often said with little thought because they are so overused in the culture; 
          they become dull, dry, and lose their shiny meaning.`,

          `They are easy to dismiss as “well, I already know that.” Next. We are programmed to constantly explore and reimagine the world as we are ready to see it. 
          Life unspools for us at our own pace. So in our journey, when a tired phrase tries to step in and waves its hands and says, 
          “hey … remember me?” We can take it for granted and shoo it away. Yeah, I already know about you. Next.`,

          `The tired old phrase fades out like a ghost in our busy lives. Maybe it will come back to haunt us again. Maybe it won’t. 
          But knowledge that can be recited is not the same as knowledge lived or earned. You may know something, but it doesn’t mean you live it. 
          Who are we to tell you to live your life by words or clichés or some ancient maxim? We’re not. People don’t do that. It’s not natural.`,

          `What My One Rule tries to do is to get those who are ready to reimagine the Golden Rule in their own moral code … to incorporate treating others the way you want to be treated … 
          in their own unique lives at their own pace. Some people aren’t ready for it yet. And so, it’s just words, an old tired cliché, 
          something that haunts when we know we’ve gone too far with someone. But static words don’t ignite. They have no power, no fire, no life.`,

          `We become what we focus on. Is it a bad thing to pull the Golden Rule from the shelves of our mind, dust it off, and look at it in a fresh light? 
          Reimagine it in our own moral code that we form for ourselves? Understand that it can come to mean something deliberate and personal? Practice it, 
          not because you should, but because it feels right? There are pearls of wisdom hidden in plain sight. We walk by them in our busy lives with barely a glance. 
          But to those who hear them calling, who are ready to reimagine how they engage with the world, those people will walk over to that pearl, 
          pick it up, dust it off, open it up … and perhaps hold hold the key to true peace and happiness.`
        ]
      },
      {
        question: "I've tried the golden rule in the past but it doesn't work. What can I do?",
        paragraphs: [
          `Do you live life on your terms or the worlds? Do you give control to the world to set your value system? 
          Do you give permission to others to shape a very personal moral code that you have to live and carry around inside of you?`,

          `Treat Others the Way You Want to be Treated is about your actions, not others. It’s about you taking control of who you are. 
          It’s about the freedom to be who you are no matter what you’re faced with. It’s about empowering you … not others. If you payback anger with anger, 
          or hate with hate, or revenge with revenge – then it changes a part of you. You become what you dislike. It stunts you. Makes you small. 
          Hate and bitterness corrupts the container.`,

          `My One Rule is not about you being a walking mat … because you wouldn’t want another to be a walking mat. It’s about maintaining a timeless principle and doing the right thing, 
          even when it’s hard … especially when it’s hard … because if you can do that, then you are truly your own person and no one elses. 
          It’s the opposite of being a walking mat. Of course there are countless benefits to living My One Rule – you’ll have more friends, you’ll be more stable, 
          you’ll be the one everyone seeks out because you’ll be the lighthouse.`,

          `The world needs more lighthouses in the storms and waves of the sea we are all trying to navigate. But you have to be ready. If it were easy, 
          then there would be more lighthouses than shipwrecks. Are you ready to reimagine the Golden Rule?`
        ]
      },
      {
        question: "You are selling products. Why isn't this website and effort free?",
        paragraphs: [
          `We believe that to practice My One Rule needs lots of fun reminders. It’s so easy to get caught up in the moment and busy in our lives, 
          that we don’t reflect enough about our actions (or inactions) towards others. Our mission is to inspire everyone to Treat Others the Way You Want to be Treated.`,

          `In pursuing that mission, we offer fun reminders and conversation starters, so that you can influence your circle of influence. 
          If you wore a shirt, hat, had a phone case cover or mug that flashed the words - My One Rule - how many people would ask you … what is your One Rule? 
          Would you have your answer ready?`,

          `It’s also about families. Our team has spent a lot of money in developing our website, artwork, and products. 
          This money has been paid to paid help (not volunteers) who are passionate about My One Rule … but they and their families need to live and eat. 
          We don’t receive subsidies or donations for our work.`
        ]
      },
      {
        question: "I've heard that we should treat others the way they want to be treated. What can I say?",
        paragraphs: [
          `We shouldn’t overcomplicate basic truths of life. You can pick apart anything. The basic rule is if you want kindness – give kindness. If you want respect – give respect. 
          If you want love – give love. If you want calm and peace – then you have to put those vibes out in the world. Life is 360. Karma. Boomerang. Aura. Whatever you want to call it.`,

          `The world gives back to us (eventually) what we give to the world – on an individual level. Let the business gurus spin basic truths so that they can profit from them … 
          but My One Rule stands on very firm footing just the way it’s written: Treat Others the Way You Want to be Treated.`
        ]
      }
    ]
  })
}
</script>